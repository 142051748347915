/*
  	Author: Paul Le Gall
  	Project: Online Portfolio
  	Year: 2022

  	Description:
  	This JavaScript file is part of my online portfolio project, created in 2022.
  	It contains various functions and code related to the portfolio website.
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Portfolio from './Portfolio';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  	<React.StrictMode>
		<Portfolio/>
  	</React.StrictMode>
);