/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This JavaScript file is part of my online portfolio project, created in 2022.
    It contains various functions and code related to the portfolio website.
*/

import {useEffect, useState} from "react";
import contact_css from "./contact.module.css"
import safari_css from "./safari.module.css"
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import {firebaseStorage} from "../config/firebase"

function Contact({isSafariOrMobile}) {
    const [downloadUrl, setDownloadUrl] = useState("www.paul-legall.com")
    const safariPatch = isSafariOrMobile ? safari_css.bg_patch : safari_css.normal_bg;
    const copyToClipboardLinkedin = () => {
        navigator.clipboard.writeText("https://www.linkedin.com/in/paul-legall");
        window.alert("LinkedIn link copied to clipboard")
    }
    const copyToClipboardGithub = () => {
        navigator.clipboard.writeText("https://github.com/luapp")
        window.alert("Github link copied to clipboard")
    }
    const copyToClipboardEmail = () => {
        navigator.clipboard.writeText("paul.legall@aol.com")
        window.alert("Email copied to clipboard")
    }

    useEffect(() => {
        const url = "gs://portfolio-paullegall.appspot.com/CV FR Public.pdf"
        getDownloadURL(ref(firebaseStorage, url))
        .then((url) => {
            setDownloadUrl(url)
        })
        .catch((error) => {
            console.log(error);
            window.alert("Download link for CV not available at the moment")
        })
    }
    , []);


    return (
        <div className={`${safariPatch} ${contact_css.about}`}>
            <div className={contact_css.contact_section}>
                <h1 className={contact_css.title}>Contact me</h1>
                <div className={contact_css.contact_sub_section}>
                    <div className={contact_css.contact_ways}>
                        <h2>Email</h2>
                        <p className={contact_css.contact_link} onClick={copyToClipboardEmail}>paul.legall@aol.com</p>
                    </div>
                    <div className={contact_css.contact_ways}>
                        <h2>GitHub</h2>
                        <p className={contact_css.contact_link} onClick={copyToClipboardGithub}>https://github.com/luapp</p>
                    </div>
                    <div className={contact_css.contact_ways}>
                        <h2>LinkedIn</h2>
                        <p className={contact_css.contact_link} onClick={copyToClipboardLinkedin}>https://www.linkedin.com/in/paul-legall</p>
                    </div>
                </div>
                <div className={contact_css.download_section}>
                    <h1>Download my curriculum vitae</h1>
                    <div className={contact_css.download}>
                        <a className={`${contact_css.download_url} ${contact_css.margin_zero}`} href={downloadUrl}>Download</a>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default Contact;