/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This JavaScript file is part of my online portfolio project, created in 2022.
    It contains various functions and code related to the portfolio website.
*/

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDC8OzKjhJeE9VasPOuqJwK_J0Ybb-xePQ",

    authDomain: "portfolio-paullegall.firebaseapp.com",
  
    projectId: "portfolio-paullegall",
  
    storageBucket: "portfolio-paullegall.appspot.com",
  
    messagingSenderId: "318373793470",
  
    appId: "1:318373793470:web:e87fdb910a9d7c8a9258ef",
    
    storageBucket: "gs://portfolio-paullegall.appspot.com"
}

const app = initializeApp(firebaseConfig);

const firebaseStorage = getStorage(app);

export {firebaseStorage}