/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This JavaScript file is part of my online portfolio project, created in 2022.
    It contains various functions and code related to the portfolio website.
*/

import { useState, useEffect, useRef } from "react";
import Header from "./components/header"
import About from "./components/about"
import Profile from "./components/profile";
import Project from "./components/project";
import Contact from "./components/contact";
import portfolio_css from "./portfolio.module.css"
import safari_css from "./components/safari.module.css"


function Portfolio() {
    const [isSafariOrMobile, setIsSafariOrMobile] = useState(false);
    const [WindowHeight, setWindowHeight] = useState(window.innerHeight);
    const [ActivePage, setActivePage] = useState("About");
    const [ScrollPosition, setScrollPosition] = useState(0);
    const ScrollRefAbout = useRef(null);
    const ScrollRefProfile = useRef(null);
    const ScrollRefProject = useRef(null);
    const ScrollRefContact = useRef(null);
    const safariPatch = isSafariOrMobile ? safari_css.bg_patch : safari_css.normal_bg;

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1;
        if (isSafari) {
            setIsSafariOrMobile(true);
        }
        const isMobile = window.innerWidth < 1023;
        if (isMobile) {
            setIsSafariOrMobile(true);
        };
    }
    , []);

    const Scroll = (e) => {
        const Position = window.pageYOffset;
        setScrollPosition(Position);
    }

    useEffect(() => {
        window.addEventListener('scroll', Scroll);
        
        return () => {
            window.removeEventListener('scroll', Scroll);
        }
    }, []);

    useEffect(() => {
        if (ScrollPosition < WindowHeight) {
            setActivePage("About");
        }
        else if (ScrollPosition >= WindowHeight && ScrollPosition < WindowHeight * 3) {
            setActivePage("Profile");
        }
        else if (ScrollPosition >= WindowHeight * 3 && ScrollPosition < WindowHeight * 4.1 - 5) {
            setActivePage("Projects");
        }
        else if (ScrollPosition >= WindowHeight * 4.1 - 5) {
            setActivePage("Contact");
        }
        setWindowHeight(window.innerHeight);
    }, [ScrollPosition]);

    return (
        <div className={` ${portfolio_css.portfolio_grid} ${safariPatch}`}>
            <div><Header ActivePage = {ActivePage} ScrollRefAbout = {ScrollRefAbout} ScrollRefProfile = {ScrollRefProfile} ScrollRefProject = {ScrollRefProject} ScrollRefContact = {ScrollRefContact} /></div>
            <div ref={ScrollRefAbout}><About isSafariOrMobile = {isSafariOrMobile}/></div>
            <div ref={ScrollRefProfile}><Profile isSafariOrMobile = {isSafariOrMobile}/></div>
            <div ref={ScrollRefProject}><Project isSafariOrMobile = {isSafariOrMobile}/></div>
            <div ref={ScrollRefContact}><Contact isSafariOrMobile = {isSafariOrMobile}/></div>
        </div>
    );
}

export default Portfolio;
