/*
  	Author: Paul Le Gall
  	Project: Online Portfolio
  	Year: 2022

  	Description:
  	This JavaScript file is part of my online portfolio project, created in 2022.
  	It contains various functions and code related to the portfolio website.
*/

import header_css from './header.module.css';

function Header({ActivePage, ScrollRefAbout, ScrollRefProfile, ScrollRefProject, ScrollRefContact}) {

	let HeaderTitle = "Paul Le Gall";

	const HandleClickAbout = () => {
		const aboutSection = ScrollRefAbout.current;
		if (aboutSection) {
			window.scrollTo({
				top: aboutSection.offsetTop - 100,
				behavior: 'smooth'
			})
		}
		HeaderTitle = "Paul Le Gall"
	}
	
	const HandleClickProfile = () => {
		console.log(ScrollRefProfile.current);
		ScrollRefProfile.current?.scrollIntoView({behavior: 'smooth'});
		HeaderTitle = "My profile"
	}
	const HandleClickProject = () => {
		console.log(ScrollRefProject.current);
		ScrollRefProject.current?.scrollIntoView({behavior: 'smooth'});
		HeaderTitle = "My projects"
	}
	const HandleClickContact = () => {
		console.log(ScrollRefContact.current);
		ScrollRefContact.current?.scrollIntoView({behavior: 'smooth'});
		HeaderTitle = "Contact me"
	}
	
	if (ActivePage === "About") {
		HeaderTitle = "Paul Le Gall";
	}
	else if (ActivePage === "Profile") {
		HeaderTitle = "My profile";
	}
	else if (ActivePage === "Projects") {
		HeaderTitle = "My projects";
	}
	else if (ActivePage === "Contact") {
		HeaderTitle = "Contact me";
	}
	else {
		HeaderTitle = "Paul Le Gall";
	}
  	return (
    	<div className={header_css.header}>
			<div className={header_css.brand_grid}>
				<h1 className={`${header_css.text} ${header_css.brand}`}>{HeaderTitle}</h1>
			</div>
			<div className={header_css.text_grid}>
				<h1 className={`${header_css.text} ${header_css.home}`} onClick={HandleClickAbout}>About</h1>
				<h1 className={`${header_css.text} ${header_css.mybuild}`} onClick={HandleClickProfile}>My profile</h1>
				<h1 className={`${header_css.text} ${header_css.aboutus}`} onClick={HandleClickProject}>My projects</h1>
				<h1 className={`${header_css.text} ${header_css.login}`} onClick={HandleClickContact}>Contact me</h1>
			</div>
      	</div>
  	);
}

export default Header;