/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This JavaScript file is part of my online portfolio project, created in 2022.
    It contains various functions and code related to the portfolio website.
*/

import project_css from "./project.module.css"
import safari_css from "./safari.module.css"

function Project({isSafariOrMobile}) {

    const safariPatch = isSafariOrMobile ? safari_css.bg_patch : safari_css.normal_bg;

    return (
        <div className={`${safariPatch} ${project_css.project}`}>
            <div className={project_css.legacy_project}>
                <div className={project_css.projects_text}>
                    <div>
                        <h1 className={project_css.legacy_title}>* Legacy Project (2017-2021)</h1>
                    </div>
                    <div>
                        <h2 className={project_css.legacy_project_title}>Covid-19 tracking 'Web'</h2>
                    </div>
                    <div>
                        <h2 className={project_css.legacy_project_title}>ToDo list 'Web'</h2>
                    </div>
                    <div>
                        <h2 className={project_css.legacy_project_title}>Phone index 'NodeJS'</h2>
                    </div>
                    <div>
                        <h2 className={project_css.legacy_project_title}>Drive eraser 'C++'</h2>
                    </div>
                    <p className={project_css.legacy_explain}>
                        * These projects represent some of my earliest work from my school days. 
                        Due to their aging codebase, they pose significant maintenance challenges, 
                        with the last update dating back to 2021. 
                        While these projects remain deployed, 
                        please note that I no longer provide active support, 
                        and their compatibility with modern browsers may be unreliable
                    </p>
                </div>
            </div>
            <div className={project_css.active_project}>
                <div className={project_css.projects_text}>
                    <div>
                        <h1 className={project_css.active_title}>Active Project (2022-Present)</h1>
                    </div>
                    <div>
                        <h2 className={project_css.active_project_title}>MYINVOX (Beta)</h2>
                    </div>
                    <p className={project_css.active_explain}>MYINVOX is a project intended for commercial purposes. It is currently in the internal testing phase before deployment</p>
                    <div>
                        <h2 className={project_css.active_project_title}>ECAPS Social Network (*WIP)</h2>
                    </div>
                    <p className={project_css.active_explain}> The ECAPS Social Network is currently under development as a commercial project, with the aim of becoming a professional, versatile social network</p>
                    <div>
                        <h2 className={project_css.active_project_title}>BITBUILD (*WIP)</h2>
                    </div>
                    <p className={project_css.active_explain}>BITBUILD is a project aimed at simplifying PC assembly and providing personalized recommendations from us to help you obtain the desktop computer that perfectly aligns with your requirements</p>
                    <div>
                        <h2 className={project_css.active_project_title}>Portfolio (DEPLOYED)</h2>
                    </div>
                    <p className={project_css.active_explain}>The website you are on now</p>
                    <p className={project_css.wip}>*Work In Progress</p>
                </div>
            </div>
        </div>
  );
}

export default Project;