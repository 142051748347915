/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This JavaScript file is part of my online portfolio project, created in 2022.
    It contains various functions and code related to the portfolio website.
*/

import profile_css from "./profile.module.css"
import safari_css from "./safari.module.css"

function ProfileLeftTop() {
    return(
        <div className={profile_css.left_box_top}>
            <div>
                <div>
                    <h1 className={profile_css.margin_zero}>Education</h1>
                </div>
                <div>
                    <p>2022-2027</p>
                    <h2>Expert en technologies de l'information</h2>
                    <p>EPITECH Paris</p>
                </div>
                <div>
                    <p>2020-2021</p>
                    <h2>BAC</h2>
                    <p>Lycée Français de Shanghai</p>
                </div>
                <div>
                    <p>2016-2017</p>
                    <h2>Brevet</h2>
                    <p>Lycée Français de Shanghai</p>
                </div>
                <div>
                    <h3>2007-2021</h3>
                    <p>Lycée Français de Shanghai</p>
                </div>
            </div>
        </div>
    );
}

function ProfileRightTop() {
    return (
        <div className={profile_css.right_box_top}>
            <div>
                <h1 className={profile_css.margin_zero}>Expertise</h1>
            </div>
            <div>
                <h2>Desktop</h2>
                <ul className={profile_css.list_style_none}>
                    <li>C</li>
                    <li>C++</li>
                    <li>Python</li>
                </ul>
                <h2>Web</h2>
                <ul className={profile_css.list_style_none}>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>NodeJS</li>
                    <li>JavaScript</li>
                    <li>TypeScript</li>
                </ul>
                <h2>Web Frameworks</h2>
                <ul className={profile_css.list_style_none}>
                    <li>ReactJS</li>
                    <li>ElectronJS</li>
                    <li>Tauri</li>
                    <li>Firebase</li>
                </ul>
                <h2>No code</h2>
                <ul className={profile_css.list_style_none}>
                    <li>Zoho</li>
                </ul>
                <h2>Other</h2>
                <ul className={profile_css.list_style_none}>
                    <li>Docker</li>
                    <li>VirtualBox</li>
                    <li>Git</li>
                    <li>Unix based Shell (Linux/MacOS)</li>
                    <li>Windows Shell</li>
                    <li>Affinity Designer</li>
                    <li>Affinity Photo</li>
                    <li>DaVinci Resolve</li>
                </ul>
            </div>
        </div>
    );
}

function ProfileLeftBottom() {
    return(
        <div className={profile_css.left_box_bottom}>
            <div>
                <h1 className={profile_css.margin_zero}>Experience</h1>
            </div>
            <div>
                <h2>AYRAKLES</h2>
                <p>Second Year Internship</p>
                <p>July 3, 2023 - December 29, 2023</p>
            </div>
        </div>
    );
}

function ProfileRightBottom() {
    return(
        <div className={profile_css.right_box_bottom}>
            <div>
                <h1 className={profile_css.margin_zero}>Languages</h1>
            </div>
            <div>
                <h3>French</h3>
                <p>Native</p>
                <h3>English</h3>
                <p>Fluent (B2-C1)</p>
                <h3>Chinese</h3>
                <p>Fluent orally</p>
            </div>
        </div>
    );
}

function Profile({isSafariOrMobile}) {
    const safariPatch = isSafariOrMobile ? safari_css.bg_patch : safari_css.normal_bg;
    return (
        <div className={`${safariPatch} ${profile_css.profile}`}>
            <ProfileLeftTop />
            <ProfileRightTop />
            <ProfileLeftBottom />
            <ProfileRightBottom />
        </div>
  );
}

export default Profile;