/*
    Author: Paul Le Gall
    Project: Online Portfolio
    Year: 2022

    Description:
    This JavaScript file is part of my online portfolio project, created in 2022.
    It contains various functions and code related to the portfolio website.
*/

import { useState, useEffect } from "react";
import memoji from "../img/memoji.png"
import about_css from "./about.module.css"

function About() {
    const [TitleCursor, setTitleCursor] = useState("|");

    useEffect(() => {
        const titleCursorBlink = () => {
            setTitleCursor(prevCursor => (prevCursor === " " ? "|" : " "));
        }
        const intervalId = setInterval(titleCursorBlink, 1000);
        return () => clearInterval(intervalId);
    }, []);


    return (
        <div className={about_css.about}>
            <div className={about_css.welcome_box}>
                <h1 className={`${about_css.margin_zero} ${about_css.welcome}`}>Welcome !</h1>
                <h1 className={`${about_css.margin_zero} ${about_css.title}`}>I am a software developper {TitleCursor}</h1>
                <h1 className={`${about_css.margin_zero} ${about_css.description}`}>I am a student in software developpement at EPITECH</h1>
                <h1 className={`${about_css.margin_zero} ${about_css.description}`}>Passionate and always interested in the latest technologies</h1>
            </div>
            <div className={about_css.welcome_picture}>
                <img className={about_css.memoji} src={memoji} alt="Loading profile (memoji)..."/>
            </div>
        </div>
  );
}

export default About;